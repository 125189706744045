<template>
  <div class="container-fluid">
    <div class="row pos-relative mg-b-70 pd-t-70 pd-b-70 bg-gray-700">
      <div class="container">
        <h1 id="title" class="tx-white tx-center-f bg-gray-500 pd-y-20 pd-x-50">{{topic.text}}</h1>
      </div>      
    </div>
  
    <div class="row">
      <div class="container pd-x-0 pd-lg-x-10 pd-xl-x-0">
        <sermon-grid :limit="9" :query="{topic_id: topic.id}"></sermon-grid>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import SermonGrid from '~/components/SermonGrid'

export default {
  layout: "player",
  metaInfo() {
    return { title: this.topic.text };
  },
  created() {
    const topic = this.topics[this.$route.params.id];

    if (!topic) {
      this.$store.dispatch('public/fetchTopics', {topic: this.$route.params.id});
    }
  },
  computed: {
    topic() {
      return this.topics[this.$route.params.id];
    },
    ...mapGetters({ 
      topics: "public/topics"
    })
  },
  components: {
    'sermon-grid': SermonGrid
  }
};
</script>

<style lang="scss" scoped>
  #title {
    border-radius: .25rem;
    
    @include media-breakpoint-up(lg) {
      position: absolute;
      left: 50%;
      transform: translate(-50%, 30%);
    }
  }
</style>
